import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { StaticImage } from "gatsby-plugin-image";
import Icon from "./Icon";
import { SmallLogo } from "./Icon/Logo";
import { media } from "../utils/mediaQueries";

const linkStlye = `
    color: var(--text-500);
    position: relative;

    transition: all 0.3s ease-in-out;

    &::after {
      content: "";
      background: var(--primary-500);

      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.3s ease-in-out;

      width: 0%;
      height: 2px;
    }

    &[aria-current="page"] {
      color: var(--primary-500);
    }

    &[aria-current="page"]::after {
      content: "";
      background: var(--primary-500);

      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.3s ease-in-out;

      width: 100%;
      height: 2px;
    }

    &:hover {
      color: var(--text-200);

      &::after {
        width: 100%;
      }
    }`;

const NavStyles = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  transition: position 0.3s ease-in-out;
  z-index: 999;
  background: #fff;
  > header {
    padding: 1rem;
  }
  // border-bottom: 1px solid var(--text-100);

  ul {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    ${media.laptop} {
      column-gap: 1.5rem;
    }
  }

  .header {
    background: #fff;
    width: 100%;
    max-height: var(--header-height);
    transition: 0.3s ease all;

    &__content {
      overflow: hidden;
      color: #fff;
      margin: 0 auto;
      max-width: 1920px;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 100;
      justify-content: space-between;

      ${media.tablet} {
        justify-content: space-between;
        overflow: visible;
      }

      &__logo {
        font-size: 2rem;
        font-weight: bold;
        text-decoration: none;
        color: #fff;
        ${media.tablet} {
          margin: 0;
        }
      }

      &__overlay {
        background: rgba(32, 32, 32, 0.9);
        backdrop-filter: blur(2px);
        position: fixed;
        width: 100%;
        height: 300vh;
        max-width: 0;
        right: 0;
        opacity: 0;
        transition: max-width 1s ease;
        transition: opacity 0.5s ease;
        &.isMenu {
          max-width: 100%;
          opacity: 1;
        }
        ${media.tablet} {
          opacity: 0;
          max-width: 0;
        }
      }

      &__nav {
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        transform: translate(100%);
        transition: all 0.3s ease;
        font-size: 0.85rem;

        .__contact {
          #contact-nav-wrapper {
            display: flex;
            flex-direction: row;
            position: absolute;

            color: var(--text-200);
            background: #fff;
            box-shadow: var(--shadow);
            border-radius: var(--radius);
            overflow: hidden;
            transition: max-height 0.3s ease;

            top: 22px;
            right: -5px;
            max-height: 0;

            > a {
              ${linkStlye};
              padding: 1rem 0.5rem;
              &:hover {
                &::after {
                  width: 60%;
                }
              }
              &::after {
                bottom: 10px;
              }
            }
          }

          &:hover {
            #contact-nav-wrapper {
              max-height: 150px;
              border: 1px solid var(--text-50);
            }
          }
        }

        #mobile_nav_logo {
          display: flex;
          width: 100%;
          justify-content: center;
          height: var(--header-height);
          ${media.tablet} {
            display: none;
          }
        }

        ${media.tablet} {
          transform: none;
          flex-direction: row;
          background: transparent;
          width: auto;
          height: 100%;
          position: static;
        }

        ul {
          list-style: none;
          padding: 0;
          display: flex;
          flex-direction: column;
          margin-bottom: 32px;
          ${media.tablet} {
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
          }
          li {
            position: relative;
            &:not(:last-child) {
              margin-bottom: 50px;
              ${media.tablet} {
                margin-bottom: 0;
              }

              .arrow {
                vertical-align: middle;
                margin-left: 10px;
              }
            }
            a {
              ${linkStlye};
            }
          }
        }

        &.isMenu {
          transform: translate(0);
          background-color: #fff;
          width: 80%;
          height: 100%;
          overflow: hidden;
        }
      }

      &__toggle {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 2rem;
        transition: 0.3s ease all;
        overflow: hidden;

        &:hover {
          color: rgb(162, 162, 246);
        }

        ${media.tablet} {
          display: none;
        }
      }
    }
  }

  ${media.tablet} {
    > header {
      left: auto;
      padding: 1rem 2.5rem;
      margin: 0 auto;
    }
  }

  ${media.desktop} {
    > header {
      max-width: 1600px;
      right: auto;
    }
  }

  .menu-close {
    position: absolute;
    right: 20px;
    top: 33px;
    right: 20px;
    top: 20px;
  }
  .secondary-btn {
    margin-bottom: 50px;
    background: #fff;
    border: 1px solid var(--primary-500);
    color: var(--primary-500);
    border-radius: var(--radius);
    padding: 0.7rem 1rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: var(--primary-500);
      color: var(--white);
      border-radius: var(--radius);
    }
    ${media.tablet} {
      margin-bottom: 0;
    }
  }
  .demo-btn {
    background: var(--primary-500);
    color: var(--white);
    border-radius: var(--radius);
    padding: 0.75rem 1rem;
    transition: background 0.3s ease-in-out;
    &:hover {
      background: var(--primary-400);
    }
  }
  > hr {
    border: none;
    height: 1px;
    background-color: var(--text-100);
  }
`;

export default function Navbar() {
  const isMobile = useMediaQuery({ query: `(max-width: ${media.sizes.tablet})` });
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    // Auto close menu if screen size is big
    if (!isMobile) {
      setMenuOpen(false);
    }
    // Disable scrolling when menu is open
    menuOpen
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "visible");
  }, [isMobile, menuOpen]);

  const menuToggleHandler = () => {
    if (isMobile) setMenuOpen((p) => !p);
  };

  return (
    <NavStyles>
      <header className="header">
        <div className="header__content">
          <Link to="/" className="header__content__logo">
            <SmallLogo />
          </Link>
          <div
            className={`header__content__overlay${menuOpen ? " isMenu" : ""}`}
            onClick={() => menuToggleHandler()}
          />
          <div className="header__content__toggle" onClick={() => menuToggleHandler()}>
            <Icon name="Menu" width="28" height="28" className="menu-icon" />
          </div>
          <nav
            className={`${"header__content__nav"}
          ${menuOpen ? `${"isMenu"}` : ""}
          }`}
          >
            <div id="mobile_nav_logo">
              <SmallLogo />
              <div onClick={() => menuToggleHandler()}>
                <Icon name="CloseMenu" width="28" height="28" className="menu-close" />
              </div>
            </div>
            <ul>
              {/* <li>
                <Link to="/">Home</Link>
              </li> */}
              <li>
                <Link to="/about" onClick={() => menuToggleHandler()}>
                  About
                </Link>
              </li>
              <li>
                <Link to="/blog" onClick={() => menuToggleHandler()}>
                  Blog
                </Link>
              </li>

              {isMobile ? (
                <>
                  {" "}
                  <li>
                    <Link to="/support" id="contact-item" onClick={() => menuToggleHandler()}>
                      Support
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" id="contact-item" onClick={() => menuToggleHandler()}>
                      Email
                    </Link>
                  </li>{" "}
                </>
              ) : (
                <li className="__contact">
                  <a className="__item">Contact</a>
                  {/* <span id="arrow" /> */}
                  <StaticImage
                    src="../assets/images/arrow_down.svg"
                    className="arrow"
                    alt="Error 404"
                    placeholder="none"
                  />
                  <div id="contact-nav-wrapper">
                    <Link to="/support" className="__item">
                      Support
                    </Link>
                    <Link to="/contact" className="__item">
                      Email
                    </Link>
                  </div>
                </li>
              )}
              <Link
                className="secondary-btn"
                to="https://app.stitch3d.io/login"
                rel="noopener noreferrer"
              >
                Log In
              </Link>
              <Link
                className="demo-btn"
                to="https://app.stitch3d.io/register"
                rel="noopener noreferrer"
              >
                Sign Up
              </Link>
            </ul>
          </nav>
        </div>
      </header>
      <hr />
    </NavStyles>
  );
}
