import React from "react";

export default function Facebook({ width = "28", height = "29", className }: any) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 29"
      fill="none"
    >
      <path
        d="M25.6663 14.9579C25.6663 8.51793 20.4397 3.29126 13.9997 3.29126C7.55967 3.29126 2.33301 8.51793 2.33301 14.9579C2.33301 20.6046 6.34634 25.3063 11.6663 26.3913V18.4579H9.33301V14.9579H11.6663V12.0413C11.6663 9.78959 13.498 7.95793 15.7497 7.95793H18.6663V11.4579H16.333C15.6913 11.4579 15.1663 11.9829 15.1663 12.6246V14.9579H18.6663V18.4579H15.1663V26.5663C21.058 25.9829 25.6663 21.0129 25.6663 14.9579Z"
        fill="white"
      />
    </svg>
  );
}
