import React from "react";

export default function Plus({ width = "28", height = "29", className }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.625 17.9219C12.9083 17.9219 13.146 17.8259 13.338 17.6339C13.5293 17.4425 13.625 17.2052 13.625 16.9219V13.9219H16.65C16.9333 13.9219 17.1667 13.8259 17.35 13.6339C17.5333 13.4425 17.625 13.2052 17.625 12.9219C17.625 12.6385 17.529 12.4009 17.337 12.2089C17.1457 12.0175 16.9083 11.9219 16.625 11.9219H13.625V8.89687C13.625 8.61354 13.5293 8.38021 13.338 8.19688C13.146 8.01354 12.9083 7.92188 12.625 7.92188C12.3417 7.92188 12.1043 8.01754 11.913 8.20888C11.721 8.40088 11.625 8.63854 11.625 8.92188V11.9219H8.6C8.31667 11.9219 8.08333 12.0175 7.9 12.2089C7.71667 12.4009 7.625 12.6385 7.625 12.9219C7.625 13.2052 7.72067 13.4425 7.912 13.6339C8.104 13.8259 8.34167 13.9219 8.625 13.9219H11.625V16.9469C11.625 17.2302 11.721 17.4635 11.913 17.6469C12.1043 17.8302 12.3417 17.9219 12.625 17.9219ZM12.625 22.9219C11.2417 22.9219 9.94167 22.6592 8.725 22.1339C7.50833 21.6092 6.45 20.8969 5.55 19.9969C4.65 19.0969 3.93767 18.0385 3.413 16.8219C2.88767 15.6052 2.625 14.3052 2.625 12.9219C2.625 11.5385 2.88767 10.2385 3.413 9.02188C3.93767 7.80521 4.65 6.74688 5.55 5.84688C6.45 4.94688 7.50833 4.23421 8.725 3.70887C9.94167 3.18421 11.2417 2.92188 12.625 2.92188C14.0083 2.92188 15.3083 3.18421 16.525 3.70887C17.7417 4.23421 18.8 4.94688 19.7 5.84688C20.6 6.74688 21.3123 7.80521 21.837 9.02188C22.3623 10.2385 22.625 11.5385 22.625 12.9219C22.625 14.3052 22.3623 15.6052 21.837 16.8219C21.3123 18.0385 20.6 19.0969 19.7 19.9969C18.8 20.8969 17.7417 21.6092 16.525 22.1339C15.3083 22.6592 14.0083 22.9219 12.625 22.9219Z"
        fill="#3461FF"
      />
    </svg>
  );
}
