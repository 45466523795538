import React from "react";

export default function LinkedIn({ width = "28", height = "29", className }: any) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 29"
      fill="none"
    >
      <path
        d="M24.2234 2.95801H3.77187C2.79219 2.95801 2 3.73145 2 4.6877V25.2236C2 26.1799 2.79219 26.958 3.77187 26.958H24.2234C25.2031 26.958 26 26.1799 26 25.2283V4.6877C26 3.73145 25.2031 2.95801 24.2234 2.95801ZM9.12031 23.4096H5.55781V11.9533H9.12031V23.4096ZM7.33906 10.3924C6.19531 10.3924 5.27188 9.46895 5.27188 8.32988C5.27188 7.19082 6.19531 6.26738 7.33906 6.26738C8.47813 6.26738 9.40156 7.19082 9.40156 8.32988C9.40156 9.46426 8.47813 10.3924 7.33906 10.3924ZM22.4516 23.4096H18.8937V17.8408C18.8937 16.5143 18.8703 14.8033 17.0422 14.8033C15.1906 14.8033 14.9094 16.2518 14.9094 17.7471V23.4096H11.3563V11.9533H14.7687V13.5189H14.8156C15.2891 12.6189 16.4516 11.6674 18.1813 11.6674C21.7859 11.6674 22.4516 14.0393 22.4516 17.1236V23.4096V23.4096Z"
        fill="white"
      />
    </svg>
  );
}
