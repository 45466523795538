import React from "react";

export default function MIT({ width, height, className }: any) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39575 36.9999V53.1874H8.82961H12.2635V36.9999V20.8124H8.82961H5.39575V36.9999ZM16.5876 31.835V42.8576H20.0214H23.4553V31.835V20.8124H20.0214H16.5876V31.835ZM27.7794 36.9999V53.1874H31.2768H34.7743V36.9999V20.8124H31.2768H27.7794V36.9999ZM39.0984 24.0247V27.237H42.5322H45.9661V24.0247V20.8124H42.5322H39.0984V24.0247ZM50.5446 24.0247V27.237H59.5743H68.6041V24.0247V20.8124H59.5743H50.5446V24.0247ZM39.0984 42.1648V53.1874H42.5322H45.9661V42.1648V31.1421H42.5322H39.0984V42.1648ZM50.5446 42.1648V53.1874H53.9784H57.4123V42.1648V31.1421H53.9784H50.5446V42.1648Z"
      />
    </svg>
  );
}
