import React from "react";

export default function Twitter({ width = "28", height = "29", className }: any) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 29"
      fill="none"
    >
      <path
        d="M9.539 24.4589C18.596 24.4589 23.5505 16.9544 23.5505 10.4579C23.5505 10.2479 23.5505 10.0349 23.5415 9.82493C24.5061 9.12665 25.3386 8.26215 26 7.27193C25.099 7.6692 24.1441 7.93103 23.1665 8.04893C24.1963 7.43338 24.9676 6.46488 25.337 5.32343C24.3695 5.89661 23.3105 6.29899 22.2065 6.51293C21.4643 5.72246 20.4821 5.1988 19.4121 5.02306C18.3421 4.84732 17.2441 5.02931 16.288 5.54083C15.3319 6.05236 14.5712 6.86487 14.1237 7.85252C13.6761 8.84016 13.5668 9.94782 13.8125 11.0039C11.8546 10.9058 9.93922 10.3971 8.19056 9.51104C6.4419 8.62495 4.89903 7.38119 3.662 5.86043C3.03401 6.94505 2.84236 8.22803 3.12597 9.44882C3.40958 10.6696 4.14718 11.7367 5.189 12.4334C4.40831 12.4069 3.64478 12.1973 2.96 11.8214V11.8889C2.96135 13.0251 3.35496 14.1261 4.07431 15.0056C4.79366 15.8851 5.79462 16.4892 6.908 16.7159C6.48539 16.8323 6.04884 16.8904 5.6105 16.8884C5.30148 16.8894 4.99307 16.8607 4.6895 16.8029C5.00418 17.781 5.61691 18.6362 6.44187 19.2486C7.26683 19.8611 8.2627 20.2002 9.29 20.2184C7.54483 21.5891 5.3891 22.3325 3.17 22.3289C2.77898 22.3306 2.38823 22.308 2 22.2614C4.25227 23.6973 6.86795 24.4597 9.539 24.4589Z"
        fill="white"
      />
    </svg>
  );
}

{
  /* <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
  <path
    d="M25.6663 14.9579C25.6663 8.51793 20.4397 3.29126 13.9997 3.29126C7.55967 3.29126 2.33301 8.51793 2.33301 14.9579C2.33301 20.6046 6.34634 25.3063 11.6663 26.3913V18.4579H9.33301V14.9579H11.6663V12.0413C11.6663 9.78959 13.498 7.95793 15.7497 7.95793H18.6663V11.4579H16.333C15.6913 11.4579 15.1663 11.9829 15.1663 12.6246V14.9579H18.6663V18.4579H15.1663V26.5663C21.058 25.9829 25.6663 21.0129 25.6663 14.9579Z"
    fill="white"
  />
</svg>; */
}
