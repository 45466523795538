import * as React from "react";
import Airforce from "./Airforce";
import ChevDown from "./ChevDown";
import Exyn from "./Exyn";
import Leica from "./Leica";
import LinkedIn from "./LinkedIn";
import Menu from "./Menu";
import CloseMenu from "./CloseMenu";
import MIT from "./MIT";
import Navy from "./Navy";
import Twitter from "./Twitter";
import Facebook from "./Facebook";
import Plus from "./Plus";
import Minus from "./Minus";
import RightArrow, { RightArrowHover } from "./RightArrow";
import Youtube from "./Youtube";

interface IconProps {
  name:
    | "Menu"
    | "CloseMenu"
    | "ChevDown"
    | "LinkedIn"
    | "Twitter"
    | "MIT"
    | "Leica"
    | "Airforce"
    | "Exyn"
    | "Navy"
    | "Facebook"
    | "Plus"
    | "Minus"
    | "RightArrow"
    | "RightArrowHover"
    | "Youtube";
  className?: string;
  width:
    | "6"
    | "12"
    | "14"
    | "16"
    | "18"
    | "20"
    | "24"
    | "26"
    | "28"
    | "36"
    | "40"
    | "48"
    | "60"
    | "80"
    | "96"
    | "112";
  height:
    | "6"
    | "12"
    | "14"
    | "16"
    | "18"
    | "20"
    | "24"
    | "26"
    | "28"
    | "36"
    | "40"
    | "48"
    | "60"
    | "80"
    | "96"
    | "112";
}

export default function Icon(props: IconProps) {
  const { name, className, width, height } = props;

  switch (name) {
    case "Menu":
      return <Menu className={className} width={width} height={height} />;
    case "CloseMenu":
      return <CloseMenu className={className} width={width} height={height} />;
    case "ChevDown":
      return <ChevDown className={className} width={width} height={height} />;
    case "LinkedIn":
      return <LinkedIn className={className} width={width} height={height} />;
    case "Twitter":
      return <Twitter className={className} width={width} height={height} />;
    case "MIT":
      return <MIT className={className} width={width} height={height} />;
    case "Airforce":
      return <Airforce className={className} width={width} height={height} />;
    case "Exyn":
      return <Exyn className={className} width={width} height={height} />;
    case "Leica":
      return <Leica className={className} width={width} height={height} />;
    case "Navy":
      return <Navy className={className} width={width} height={height} />;
    case "Facebook":
      return <Facebook className={className} width={width} height={height} />;
    case "Plus":
      return <Plus className={className} width={width} height={height} />;
    case "Minus":
      return <Minus className={className} width={width} height={height} />;
    case "RightArrow":
      return <RightArrow className={className} width={width} height={height} />;
    case "RightArrowHover":
      return <RightArrowHover className={className} width={width} height={height} />;
    case "Youtube":
      return <Youtube className={className} width={width} height={height} />;
    default:
      return <p>error</p>;
  }
}
