import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { media } from "../utils/mediaQueries";
import Icon from "./Icon";
import { WhiteLogo } from "./Icon/Logo";

const FooterStyles = styled.footer`
  background: var(--primary-800);
  color: #fff;
  width: 100%;
  padding: var(--footer-section-padding);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .link-wrapper {
    margin: 0 0 3rem 0;
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    column-gap: 2rem;

    .page-collection {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      a {
        color: #fff;
        cursor: pointer;
        padding: 0 0 3rem 0;
        text-align: left;
      }
    }
    .--dk {
      display: none;
    }
  }

  .logo {
    .__wrapper {
      > svg {
        height: 26px;
      }
      > p {
        margin-top: 1rem;
        font-size: 1rem;
      }
    }
    .__socials {
      margin: 3rem 0 1rem 0;
      > a:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  ${media.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .logo {
      order: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .__wrapper {
        > svg {
          margin-left: -40px;
        }
      }
      .__socials {
        margin: 2.5rem 0 1rem 0;
      }
    }
    .link-wrapper {
      order: 2;
      grid-template-columns: 1.5fr 1fr 1fr;
      column-gap: 3rem;
      margin: 0;
      padding: 0;

      .--md {
        display: none;
      }
      .--dk {
        display: flex;
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <div className="link-wrapper">
        <div className="page-collection --md --1">
          <Link to="/about">About Us</Link>
          <Link to="/support">Support</Link>
          <Link to="/contact">Request a Demo</Link>
          {/* <Link to="/login">Account</Link> */}
          <Link to="/blog">Blog</Link>
        </div>
        <div className="page-collection --md --2">
          <Link to="/faq">FAQs</Link>
          <Link to="/pricing">Pricing</Link>
          <Link to="/terms">Terms of use</Link>
          <Link to="/privacy">Privacy</Link>
        </div>
        <div className="page-collection --dk --1">
          <Link to="/about">About Us</Link>
          <Link to="/support">Support</Link>
          <Link to="/contact">Request a Demo</Link>
        </div>
        <div className="page-collection --dk --2">
          {/* <Link to="/login">Account</Link> */}
          <Link to="/pricing">Pricing</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/faq">FAQs</Link>
        </div>
        <div className="page-collection --dk --3">
          <Link to="/terms">Terms of use</Link>
          <Link to="/privacy">Privacy</Link>
        </div>
      </div>
      <div className="logo">
        <div className="__wrapper">
          <WhiteLogo />
          <p>Boston, Massachusetts</p>
        </div>
        <div className="__socials">
          <a href="https://www.linkedin.com/company/candelytics/" target="_blank" rel="noreferrer">
            <Icon name="LinkedIn" width="28" height="28" />
          </a>
          <a href="https://twitter.com/stitch_3D" target="_blank" rel="noreferrer">
            <Icon name="Twitter" width="28" height="28" />
          </a>
          <a href="https://www.facebook.com/Stitch3D.io" target="_blank" rel="noreferrer">
            <Icon name="Facebook" width="28" height="28" />
          </a>
          <a href="https://www.youtube.com/@Stitch_3D" target="_blank" rel="noreferrer">
            <Icon name="Youtube" width="36" height="26" />
          </a>
        </div>
      </div>
    </FooterStyles>
  );
}
