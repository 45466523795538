import React from "react";
import Footer from "./Footer";
import "normalize.css";
import GlobalStyles from "../styles/GlobalStyles";
import { Typography } from "../styles/Typography";
import Navbar from "./NavBar";

export default function Layout({ children, location }: any) {
  return (
    <div>
      <GlobalStyles />
      <Typography />
      <Navbar location={location} />
      {children}
      <Footer />
    </div>
  );
}
