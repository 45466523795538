import React from "react";

export default function RightArrow({ width, height, className }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        d="M8.1543 0.92186L14.4999 7.26751L8.1543 13.6132"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4999 7.26752L1 7.26752"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function RightArrowHover({ width, height, className }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M9.4043 3.57617L15.7499 9.92182L9.4043 16.2675"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7499 9.92188L2.25 9.92188"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
