import { createGlobalStyle } from "styled-components";
import { media } from "../utils/mediaQueries";

export const Typography = createGlobalStyle`
    h1 {
        font-weight: 600;
        font-size: 3rem;

        ${media.tablet} {
            font-size: 3.5rem;
            line-height: 4.375rem;
        }
    }

    h2 {
        font-weight: 400;
        font-size: 2.25rem;
        line-height: 3rem;

        ${media.tablet} {
            font-size: 2.75rem;
        }
    }

    h3 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.5rem;

        ${media.tablet} {
            font-size: 1.75rem;
        }
    }

    h4 {
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.5rem;

        ${media.tablet} {
            font-size: 1.25rem;
        }
    }

    h5 {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
    }

    p {
        font-weight: 400;
        font-size: 0.85rem;
        line-height: 1.35rem;
    }

a {
    text-decoration: none;
}

    .legal {
        font-weight: 400;
        font-size: 0.85rem;
        line-height: 1.1rem;
        letter-spacing: 0.16px;
    }

    .error {
        font-weight: 400;
        font-size: 0.85rem;
        line-height: 1.1rem;
        letter-spacing: 0.16px;
        color: var(--util-error);
    }
`;
