import { createGlobalStyle } from "styled-components";
import { media } from "../utils/mediaQueries";

const GlobalStyles = createGlobalStyle`
  :root {
    // PRIMARY
    --primary-50: #e6efff;
    --primary-100: #b0ccff;
    --primary-200: #8ab4ff;
    --primary-300: #5492ff;
    --primary-400: #337dff;
    --primary-500: #005cff; //🌟
    --primary-600: #0054e8;
    --primary-700: #0041b5;
    --primary-800: #00338c;
    --primary-900: #00276b;
    --footer-bg: #001842;

    // TEXT
    --white: #ffffff;
    --bg: #f4f4f4;
    --text-50: #e9e9e9;
    --text-100: #bcbcbc;
    --text-200: #9b9b9b;
    --text-300: #6e6e6e;
    --text-400: #515151;
    --text-500: #262626; //🌟
    --text-600: #232323;
    --text-700: #1b1b1b;
    --text-800: #151515;
    --text-900: #101010;

    // UTILITY
    --util-error: #ff8389;
    --util-warning: #f1c21b;
    --util-success: #42be65;

    /* inset | offset-x | offset-y | blur-radius | color */
    --inner-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12); // text inputs

    --shadow: 3px 4px 12px -2px rgba(38, 38, 38, 0.2); // base shadow
    --shadow--hover: 5px 6px 12px -2px rgba(38, 38, 38, 0.21); // quick actions hover event on homepage
    --shadow--inverse: -3px 4px 12px -2px rgba(0, 0, 0, 0.25); // just for preview panel

    --shadow--dropdown: 1px 1px 2px rgba(0, 0, 0, 0.12); // just for dropdown menus

    --radius: 2px; // border radius

    --header-height: 5rem;

    --footer-section-padding: 3.5rem;
    --hero-section-padding: 1rem 2rem;
    --section-padding: 5rem 2rem 5rem 2rem;
    --cta-section-padding: 2rem 2rem 4rem 2rem;
    --header-section-padding: 0 2rem;

    ${media.tablet} {
      --header-height: 4.5rem;
      --footer-section-padding: 4rem;
      --hero-section-padding: 2rem 4rem;
      --section-padding: 4rem 4rem 4rem 4rem;
      --cta-section-padding: 2rem 4rem 4rem 4rem;
      --header-section-padding: 0 4rem;

    }

    ${media.laptop} {
      --footer-section-padding: 4rem 6rem 1rem 6rem;
      --hero-section-padding: 2.5rem 6rem;
      --section-padding: 5rem 6rem 5rem 6rem;
      --cta-section-padding: 2rem 6rem 6rem 6rem;
      --header-section-padding: 0 6rem;

    }

    ${media.desktop} {
      --hero-section-padding: 4rem 6rem;
      --footer-section-padding: 4rem 20rem 1rem 20rem;
      --section-padding: 5rem 10rem 5rem 10rem;
      --cta-section-padding: 2rem 20rem 6rem 20rem;
      --header-section-padding: 0 20rem;

    }
  }

  html {
    box-sizing: border-box;
    /* -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; */
    font-size: 100%; // 16px
    // background-color: var(--bg);
  }

  body {
    font-family: "IBM Plex Sans", --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    max-width:1600px;
    background: #fff;
    margin: 0 auto !important;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;

    margin: 0;
    padding: 0;
  }

  ul, ol {
    list-style: none;
  }

  button {
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 1.35rem;
    border: none;
    font-weight: 400;
    border-radius: 2px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  button.primary {
    background: var(--primary-500);
    color: var(--white);

    &:hover {
      background: var(--primary-400);
    }
  }

  button.secondary {
    border: 1px solid var(--primary-500);
    color: var(--primary-500);
    background: transparent;

    &:hover {
      background: var(--primary-500);
      color: var(--white);
    }
  }

  button.normal {
    padding: 0.5rem 1rem;
  }

  button.small {
    padding: 0.25rem 1rem;
  }

  button.full-width {
    width: 100%;
    padding: 0.75rem 1rem;
  }

  img {
    max-width: 100%;
  }
  main {
    padding-top: var(--header-height);
  }
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

`;

export default GlobalStyles;
